import React from 'react';
import { NextPage } from 'next';
import dynamic from 'next/dynamic';

const Simulator = dynamic(
  () => import('../containers/Simulator').then(i => i.Simulator),
  {
    ssr: false,
  }
);

const Index: NextPage = () => (
  <div
    style={{
      position: 'relative',
      width: '100vw',
      height: '100vh',
      overflow: 'hidden',
    }}
  >
    <Simulator />
  </div>
);

export default Index;
